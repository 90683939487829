import { AccordionProps } from "@mui/material";

const useAccordionVariant = (variant: string) => {
  const blueBg = "#F3F9FF";
  const borderedStyle: AccordionProps["sx"] = (() => {
    const baseBorderStyle = {
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: "4px",
      "&.Mui-expanded": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    };
    switch (variant) {
      case "blue":
        return {
          ...baseBorderStyle,
          borderColor: "rgba(38, 105, 170, .5)"
        };
      case "grey":
        return {
          ...baseBorderStyle,
          color: "common.black",
          borderColor: "borders.main",
          "&.Mui-expanded": {
            ...baseBorderStyle["&.Mui-expanded"],
            color: "brand.main",
            backgroundColor: blueBg,
            minHeight: "auto"
          }
        };
      case "white":
        return {
          ...baseBorderStyle,
          borderColor: "rgba(224, 224, 224, 1)"
        };
      default:
        return {};
    }
  })();
  const expandIconColor = (() => {
    switch (variant) {
      case "blue":
      case "grey":
        return "brand.main";
      case "white":
        return "component.textColor";
    }
  })();

  const accordionSummaryBackgroundColor = (() => {
    switch (variant) {
      case "blue":
        return blueBg;
      case "grey":
        return "#F5F5F5";
      case "white":
        return "#ffffff";
    }
  })();

  return {
    borderedStyle,
    expandIconColor,
    accordionSummaryBackgroundColor
  };
};

export default useAccordionVariant;
