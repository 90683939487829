import { TopicDashboardSectionConfig } from "../util/elementHelpers/dashboard/types";
import { MhcPageFragment, MhcTopicCategoryEnum, MhcTopicTypeEnum } from "graphqlApi/types";

import { cprSectionTopics } from "modules/CPR/content/cprSections";
// import climateChangeSections from "../util/elementHelpers/ClimateChange/sections";
import leadSections from "../util/elementHelpers/Lead/sections";

interface TopicWithSections {
  id: string;
  parentId?: string;
  category: MhcTopicCategoryEnum;
  sections: (locationId: string) => MhcPageFragment[];
}

interface Params {
  sections: TopicDashboardSectionConfig[];
  category: MhcTopicCategoryEnum;
}
const transformSectionsToNavItems = ({ sections, category }: Params) =>
  sections.map(({ id, title }) => ({
    id,
    name: title ?? "",
    category,
    href: `#${id}`,
    slug: id,
    pageId: id,
    topicType: MhcTopicTypeEnum.Section
  }));

const topicsWithSections: TopicWithSections[] = [
  {
    id: "cpr",
    parentId: "cpr",
    category: MhcTopicCategoryEnum.Portals,
    sections: (locationId) =>
      locationId && locationId !== "state" ? cprSectionTopics(locationId) : []
  },
  {
    id: "lead",
    parentId: "maternal-and-child-health",
    category: MhcTopicCategoryEnum.AllTopics,
    sections: () =>
      transformSectionsToNavItems({
        sections: leadSections,
        category: MhcTopicCategoryEnum.AllTopics
      })
  }
];

export default topicsWithSections;
