import compact from "lodash/compact";
import last from "lodash/last";
import { subDays } from "date-fns/subDays";

import { FetchKpiOptions, KpiPropsAndRelatedKpis } from "./types";
import { MhcTarget, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { getStatTypeByStatId } from "./util";
import { formatDateByGranularity, formatValueByUnit } from "common/util/formatHelpers";
import {
  getValueOptionsFromSi,
  targetForStatIdentifier
} from "common/util/formatHelpers/statIdentifierHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { EnhancedKpiProps, KpiProps } from "common/components/KPI";
import { KpiTargetProps } from "common/components/KPI/KpiTarget";
import { LoadedStat } from "../fetchStatsForAllSections";
import createTargetProps from "./createTargetProps";

interface CreateKpisArgs extends LoadedStat {
  locationId: string;
  relatedKpis?: KpiProps[];
  options?: FetchKpiOptions;
}

/**
 * Receives a loaded location stat and returns the props to render a
 * KPI component (customizable with an options object)
 *
 * @param params - Object containing the locationId and options
 * @param params.locationId - ID or slug of the geographic location
 * @param params.options - Set of options to customize the KPI
 * @param params.loadedStat - Spread object containing the loaded stat properties (LoadedStat)
 * @param params.relatedKpis
 *
 * @returns Props for KPI component as KpiPropsAndRelatedKpis
 *
 * @see CreateKpisArgs - Type of the params object
 * @see LoadedStat - Object containing the loaded stat properties
 * @see FetchKpiOptions - Options to customize the KPI
 * @see KPI - Component
 * @see KpiPropsAndRelatedKpis - Props for KPI component
 *
 */
export const createKpiProps = ({
  locationId,
  options = {},
  relatedKpis = [],
  ...loadedStat
}: CreateKpisArgs): KpiPropsAndRelatedKpis => {
  const {
    statIdentifier,
    lastValue: _lastValue,
    lastUpdatedOn: _lastUpdatedOn,
    timeSeries,
    percentageChange
  } = loadedStat;

  // If a time series is loaded, use it as the source for last value and last updated date
  // in case the times series was windowed by a start or end date
  let lastValue = _lastValue;
  timeSeries && (lastValue = last(timeSeries.values));
  let lastUpdatedOn = _lastUpdatedOn;
  timeSeries && timeSeries.dates && (lastUpdatedOn = last(timeSeries.dates));

  const {
    alternateValueCallback,
    enhancements = {},
    granularity: statGranularity,
    includeSiObject = false,
    includeTarget = true,
    includeTrend = true,
    includePercentChange = true,
    statIdsWithTrend,
    statIdTopicInfoDictionary,
    statRepresentsRange = false,
    valueCaptionCallback,
    valueSize = "large"
  } = options;
  const {
    id,
    name,
    unit,
    precision,
    unitLabel,
    description,
    addendum,
    subtitle,
    isPercent,
    ageAdjusted,
    statCaption
  } = statIdentifier;

  let viewMoreEnhancement: Pick<
    EnhancedKpiProps,
    "viewMoreUrl" | "viewMoreDescription" | "viewMoreShowWhenSuppressed"
  > = {};

  if (statIdTopicInfoDictionary && statIdTopicInfoDictionary[id]) {
    viewMoreEnhancement = {
      viewMoreUrl: statIdTopicInfoDictionary[id]?.path,
      viewMoreDescription:
        statIdTopicInfoDictionary[id]?.linkText ||
        `View more ${statIdTopicInfoDictionary[id]?.name ?? ""} data`,
      viewMoreShowWhenSuppressed: true
    };
  }

  let targetProps: KpiTargetProps | undefined;
  const additionalInformation = "";
  const siTarget = includeTarget ? targetForStatIdentifier(statIdentifier) : ({} as MhcTarget);
  if (lastValue && includeTarget && siTarget?.value) {
    targetProps = createTargetProps({
      statSentence: loadedStat.sentence,
      siTarget,
      lastValue,
      statIdentifier,
      timeSeries,
      percentageChange
    });
  }

  const formattedValue = lastValue
    ? formatValueByUnit({ value: lastValue, unit, precision, isPercent })
    : null;

  const granularity =
    loadedStat?.granularity ??
    loadedStat?.timeSeries?.granularity ??
    statGranularity ??
    MhcTimeSeriesGranularityEnum.Year;

  // Establish dataDatePeriod based on data type and granularity
  const lastUpdatedDateObj = getUtcDateFromString(lastUpdatedOn);
  let dataDatePeriod =
    formatDateByGranularity({
      value: lastUpdatedDateObj,
      granularity
    }) ?? "";
  if (statRepresentsRange) {
    dataDatePeriod = "Range";
  } else if (granularity === MhcTimeSeriesGranularityEnum.Week) {
    const startDate = lastUpdatedDateObj ? subDays(lastUpdatedDateObj, 6) : null;
    let startDateString = "";
    if (startDate) {
      startDateString = formatDateByGranularity({
        value: startDate,
        granularity
      });
    }
    const dates = compact([startDateString, dataDatePeriod]).join(" - ");
    dataDatePeriod = `(${dates})`;
  } else {
    const date = getUtcDateFromString(lastUpdatedOn);
    dataDatePeriod = date ? formatDateByGranularity({ value: date, granularity }) : "";
  }

  // Establish props to render trend if data is available
  let trendProps = null;
  if (timeSeries && (includeTrend || statIdsWithTrend?.includes(id))) {
    trendProps = {
      title: name ?? "",
      subtitle: subtitle ?? "",
      statCaption: statCaption ?? "",
      timeSeries: timeSeries,
      granularity,
      height: 65,
      valueOptions: getValueOptionsFromSi(statIdentifier)
    };
  }

  let percentSentenceProps = null;
  if (includePercentChange && timeSeries && timeSeries.dates && timeSeries.values.length > 1) {
    percentSentenceProps = {
      percentageChange,
      firstValue: timeSeries.values[0],
      lastValue: timeSeries.values[timeSeries.values.length - 1],
      startDate: timeSeries.dates[0],
      endDate: timeSeries.dates[timeSeries.dates.length - 1],
      precision,
      granularity
    };
  }

  let title = name;
  if (relatedKpis?.length || options.forceRelatedStatTitle) {
    title = getStatTypeByStatId(id, ageAdjusted, isPercent ?? false);
  }

  const kpiProps: KpiPropsAndRelatedKpis = {
    id,
    isPercent: isPercent,
    percentSentenceProps,
    valueSize: typeof valueSize === "string" ? valueSize : valueSize(loadedStat.id),
    rawValue: lastValue ?? null,
    statCaption:
      (valueCaptionCallback && valueCaptionCallback(formattedValue, id)) ||
      statCaption ||
      subtitle ||
      addendum ||
      unitLabel,
    additionalInformation: additionalInformation || null,
    title,
    info: description ?? "",
    value:
      ((alternateValueCallback && alternateValueCallback(formattedValue ?? null, id)) ||
        formattedValue) ??
      null,
    enhancement: {
      ...enhancements,
      ...viewMoreEnhancement,
      granularity,
      dataDatePeriod,
      date: lastUpdatedOn ?? null,
      targetProps: targetProps ?? null,
      trendProps
    },
    sx: {
      width: "100%",
      p: 2,
      mb: 2
    },
    relatedKpis,
    kpiGroupTitle: name,
    ageAdjusted: ageAdjusted ?? false,
    si: includeSiObject ? statIdentifier : null
  };
  return kpiProps;
};
