import compact from "lodash/compact";
import isNil from "lodash/isNil";
import { format } from "date-fns/format";
import {
  AccessibilityOptions,
  AccessibilityPointOptions,
  ChartOptions,
  Options,
  PlotOptions,
  YAxisOptions
} from "highcharts";

import { ChartProps } from "../Chart/types";

import { fontFamily } from "theme/theme";
import highchartsAccessibilityOptions from "common/components/charts/util/highchartsAccessibilityOptions";
import { formatValueByUnit } from "common/util/formatHelpers";
import { normalizeDate } from "common/util/utcDateFromString";

import { titleHtmlText } from "./titleHtmlText";

export const chartBodyColors = {
  axisLineColor: "#000000"
};

export const highchartOptions: Options = {
  chart: {
    style: {
      fontFamily
    }
  },
  lang: {
    thousandsSep: ",",
    numericSymbolMagnitude: 1000,
    decimalPoint: "."
  }
};

type PlotOptionsParams = {
  lineMarksEnabled?: boolean;
  options?: PlotOptions;
};

export const plotOptions = ({
  lineMarksEnabled = true,
  options = {}
}: PlotOptionsParams): PlotOptions => {
  const descriptionFormatter: AccessibilityPointOptions["descriptionFormatter"] = (point) => {
    const seriesName = point.series.name;
    const isCategorical = typeof point.category === "string";
    let formattedContext = null;
    if (!isCategorical && !isNil(point.x)) {
      formattedContext = format(normalizeDate(new Date(point.x)), "PPP");
    } else if (isCategorical) {
      formattedContext = point.category;
    }
    const valueDescription = point.series.yAxis.options?.title?.text ?? "";
    const label = compact([
      isCategorical ? seriesName : null,
      formattedContext ? `${formattedContext}:` : null,
      point.y ?? "",
      !isCategorical && (seriesName ?? "").toLocaleLowerCase(),
      valueDescription ? `(${valueDescription.toLocaleLowerCase()})` : null
    ]).join(" ");
    return label;
  };
  const lineWidth = 3;
  return {
    ...options,
    line: {
      accessibility: {
        point: { descriptionFormatter }
      },
      connectNulls: true,
      lineWidth,
      marker: {
        enabled: lineMarksEnabled,
        radius: lineWidth + 2,
        ...options.line?.marker
      },
      ...options.line
    },
    column: {
      accessibility: {
        point: { descriptionFormatter }
      },
      ...options.column
    },
    bar: {
      accessibility: {
        point: { descriptionFormatter }
      },
      ...options.bar
    }
  };
};

type BaseChartOptionsParams = {
  accessibilityOptions?: AccessibilityOptions;
  animate?: boolean;
  chartOptions?: ChartOptions;
  dateString?: string;
  height: ChartOptions["height"];
  hideTitleOnUI: boolean;
  subtitle?: string;
  title: string;
  width: ChartOptions["width"];
};

export const baseChartOptions = ({
  accessibilityOptions,
  animate,
  chartOptions = {},
  dateString,
  height,
  hideTitleOnUI,
  subtitle,
  title,
  width
}: BaseChartOptionsParams): Options => ({
  accessibility: highchartsAccessibilityOptions({ ...accessibilityOptions, description: title }),
  chart: {
    ...chartOptions,
    height,
    width,
    backgroundColor: "transparent",
    animation: animate ?? true,
    style: {
      fontFamily
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: hideTitleOnUI ? undefined : titleHtmlText({ title, subtitle, dateString }),
    align: "left",
    useHTML: true
  }
});

type BaseYAxisOptionsParams = {
  options?: YAxisOptions;
  percent?: boolean;
  precision?: number;
  seriesMax?: number;
  unit?: string;
  yAxisTitle?: ChartProps["yAxisTitle"];
};

export const baseYAxisOptions = ({
  options,
  percent,
  precision,
  seriesMax,
  unit,
  yAxisTitle
}: BaseYAxisOptionsParams): Options["yAxis"] => ({
  ...(options ?? {}),
  lineColor: chartBodyColors.axisLineColor,
  lineWidth: 1,
  tickColor: chartBodyColors.axisLineColor,
  minorTickColor: chartBodyColors.axisLineColor,
  tickPosition: "outside",
  endOnTick: false,
  startOnTick: false,
  tickInterval: percent ? 5 : undefined,
  tickLength: percent ? 5 : undefined,
  tickWidth: percent ? 1 : undefined,
  title: {
    text: yAxisTitle
  },
  labels: {
    step: 2,
    formatter: ({ value }) => {
      const formattedValue = formatValueByUnit({
        value: typeof value === "number" ? value : parseInt(value) ?? 0,
        unit: percent ? "percent" : unit ?? "count",
        precision,
        isPercent: percent ?? false
      });
      return formattedValue;
    },
    style: {
      color: "black"
    },
    ...(options?.labels ?? {})
  },
  min: options?.min ?? 0,
  max: percent ? (seriesMax && seriesMax > 50 ? 100 : 50) : options?.max ?? undefined
});

type BaseXAxisOptionsParams = {
  xAxisTitle?: string;
};
export const baseXAxisOptions = ({ xAxisTitle }: BaseXAxisOptionsParams): Options["xAxis"] => ({
  title: xAxisTitle
    ? {
        text: xAxisTitle
      }
    : undefined,
  lineColor: chartBodyColors.axisLineColor,
  tickColor: chartBodyColors.axisLineColor,
  minorTickColor: chartBodyColors.axisLineColor,
  minorGridLineWidth: 0,
  gridLineWidth: 1,
  tickLength: 5,
  minPadding: 0,
  maxPadding: 0
});
