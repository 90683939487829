import { Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcLocationStatFragment, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";
import { formatDateFromUTC } from "common/util/utcDateFromString";

import { SecondaryTableCellLabel } from "common/components/IndicatorTable/columns";
import { SuppressionTooltip } from "common/components/SuppressionTooltip/SuppressionTooltip";
import { IndicatorTableRow } from "../IndicatorTable";

interface Props extends IndicatorTableRow {
  useRatioForValues?: boolean;
}

export const LocationValueWrapper: React.FC<Props> = ({ useRatioForValues, ...row }) => {
  const { locationValue, si, locationStat = {}, latestDate } = row;

  const { lastUpdatedOn = "", granularity = MhcTimeSeriesGranularityEnum.Year } =
    locationStat as MhcLocationStatFragment;
  let formattedDate: string | null = "";
  (lastUpdatedOn || latestDate) &&
    (formattedDate = formatDateFromUTC(
      lastUpdatedOn || latestDate,
      granularity as MhcTimeSeriesGranularityEnum
    ));

  return (
    <>
      {row.locationValue !== undefined && (
        <Stack gap="2px">
          <Typography fontWeight={600} variant="body2" sx={{ my: "auto" }}>
            {useRatioForValues === true ? "1 : " : ""}
            {formatValueByUnit({
              value: locationValue,
              ...getValueOptionsFromSi(si)
            })}
          </Typography>
          {formattedDate && <SecondaryTableCellLabel>{formattedDate}</SecondaryTableCellLabel>}
        </Stack>
      )}
      {isNil(locationValue) && <SuppressionTooltip fontSize="small" />}
    </>
  );
};
