import { Link } from "@mui/material";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";

interface Props {
  handleClick: () => void;
  asReset?: boolean;
  disabled?: boolean;
}

const InvestigateChartClearSelection: React.FC<Props> = ({
  handleClick,
  asReset = false,
  disabled = false
}) => {
  const isMobile = useIsMobile("lg");

  return (
    <Link
      component="button"
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        sendGaUserInteractionEvent({
          category: "Charts",
          action: "Investigate Dropdown Clear Selection"
        });
        handleClick();
      }}
      sx={{
        mt: "auto",
        mb: { xs: 0, lg: 2 },
        display: "flex",
        width: isMobile ? undefined : "fit-content",
        minWidth: "none",
        whiteSpace: "nowrap",
        justifyContent: { xs: "flex-start", lg: "center" },
        flex: { xs: 1, lg: 0 },
        "&[disabled]": {
          color: "action.disabled",
          pointerEvents: "none",
          cursor: "not-allowed"
        }
      }}
      variant="body2"
      underline="hover"
    >
      {asReset ? "Reset selections" : "Clear selection"}
    </Link>
  );
};

export default InvestigateChartClearSelection;
