"use client";

import Link from "next/link";
import { Box, Link as MuiLink, Stack, Typography, TypographyProps } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import type { IndicatorColumnIds } from "../types";
import { TimeScaleEnum } from "common/components/charts/types";
import { TableColumn } from "common/components/DataTable/types";
import { FcWithChildren } from "common/components/types";
import {
  MhcLocationFragment,
  MhcStatIdentifierFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { columnIdsOptions } from "../constants";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";

import { InfoIcon } from "common/components/InfoIcon";
import {
  SuppressionTooltip,
  SuppressionTooltipNA
} from "common/components/SuppressionTooltip/SuppressionTooltip";
import { InfoButtonWithModal } from "component/button/InfoButton/InfoButton";
import { IndicatorTableProps, IndicatorTableRow } from "../IndicatorTable";
import {
  genericTableCell,
  percentChangeTableCell,
  primaryStatTableCell,
  trendLineTableCell
} from "./cellContent";

type IndicatorColumns = { [K in IndicatorColumnIds]: TableColumn<IndicatorTableRow> };

interface GetColumnParams {
  location: MhcLocationFragment;
  useCounty?: boolean;
  useRatioForValues?: boolean;
  title?: string;
  showAllColumns?: boolean;
  columnWidths?: IndicatorTableProps["columnWidths"];
  columnsToShow?: IndicatorColumnIds[];
  columnTitleOverrides?: IndicatorTableProps["columnTitleOverrides"];
  renderGhostColumns?: boolean;
  statColumnNames?: {
    primary: string;
    related: string;
  };
  isMobile?: boolean;
}

export const COLUMN_WIDTHS = {
  primary: "30%",
  secondary: "15%",
  tertiary: "15%"
};

export const SecondaryTableCellLabel: FcWithChildren<TypographyProps> = ({
  children,
  ...props
}) => (
  <Typography
    variant="caption"
    fontWeight={400}
    color="light.secondary"
    component="span"
    {...props}
  >
    {children}
  </Typography>
);

const columnContentMaxWidth = (grow?: number, isMobile = false) =>
  isMobile ? undefined : `${200 + (grow ?? 0)}px`;

export const getColumns = ({
  location,
  useCounty,
  useRatioForValues,
  title,
  columnWidths = {},
  columnsToShow: _columnsToShow = columnIdsOptions,
  columnTitleOverrides = {},
  renderGhostColumns = true,
  statColumnNames = { primary: "Rate", related: "Count" },
  isMobile = false
}: GetColumnParams): IndicatorColumns => {
  const noRenderColumn = (id: string, width?: string): TableColumn<IndicatorTableRow> => ({
    id,
    display: "",
    width: width ?? COLUMN_WIDTHS.tertiary,
    render: () => <Box maxWidth={columnContentMaxWidth(undefined, isMobile)} />
  });

  const columnsToShow = isEmpty(_columnsToShow) ? columnIdsOptions : _columnsToShow;

  // Base columns
  const columns: IndicatorColumns = {};

  // Linked topic title
  if (columnsToShow.includes("topic")) {
    columns["topic"] = genericTableCell({
      id: "topic",
      display: columnTitleOverrides.topic ?? "Topic Page",
      align: "left",
      width: columnWidths["topic"] ?? "25%",
      sortable: true,
      render: ({ topic }) => {
        return (
          <MuiLink
            href={topic?.href ?? "#"}
            component={Link}
            variant="body1"
            onClick={(event) => event.stopPropagation()}
          >
            {topic?.name ?? "Topic"}
          </MuiLink>
        );
      }
    });
  }

  columns["indicator"] = {
    id: "indicator",
    display: columnTitleOverrides.indicator ?? `${title ?? ""} Indicator`.trim(),
    sortable: true,
    width: columnWidths["indicator"] ?? COLUMN_WIDTHS.primary,
    render: ({ si, locationStat }) => {
      const { granularity = MhcTimeSeriesGranularityEnum.Year } = locationStat ?? {};
      let granularityText = "";
      if (granularity === MhcTimeSeriesGranularityEnum.Month) {
        granularityText = TimeScaleEnum.Monthly;
      }
      return (
        <Stack>
          <Typography variant="body2" fontWeight={400} color="light.primary">
            {si.fullName ?? "Topic"}
          </Typography>
          {si.subtitle && (
            <SecondaryTableCellLabel>
              {granularityText} {si.subtitle}
            </SecondaryTableCellLabel>
          )}
        </Stack>
      );
    }
  };

  // Location stat with location name as heading
  if (columnsToShow.includes("location")) {
    columns["location"] = primaryStatTableCell({
      id: "location",
      width: columnWidths["location"],
      display: (
        <Typography fontWeight={700} variant="body2">
          {useCounty !== undefined ? location.county?.name : location.name}{" "}
          {useCounty === true && location.county !== undefined && location.county !== null ? (
            <InfoButtonWithModal
              title="About this indicator"
              subtitle="Why is this at county level?"
              description={`Statistics shown are from data for ${location.county.name} because there are insufficient data for ${location.name}.`}
            />
          ) : null}
        </Typography>
      )
    });
  }

  // Location stat with custom heading
  if (columnsToShow.includes("primaryStat")) {
    columns["primaryStat"] = primaryStatTableCell({
      id: "primaryStat",
      width: columnWidths["primaryStat"],
      display: statColumnNames.primary
    });
  }

  // Related Stat of identifier eg count if primary is percent/rate
  if (columnsToShow.includes("relatedStat")) {
    columns["relatedStat"] = {
      id: "relatedStat",
      align: "center",
      display: statColumnNames.related,
      sortable: true,
      width: columnWidths["relatedStat"] ?? COLUMN_WIDTHS.tertiary,
      render: ({ relatedValue, relatedSi }) => {
        const formatedValue = formatValueByUnit({
          value: relatedValue,
          ...getValueOptionsFromSi((relatedSi || {}) as MhcStatIdentifierFragment)
        });
        return (
          <Box textAlign="center">
            {formatedValue ? (
              <Typography variant="body2">{formatedValue}</Typography>
            ) : (
              <SuppressionTooltip fontSize="small" />
            )}
          </Box>
        );
      }
    };
  }

  // Percent of change over time
  if (columnsToShow.includes("percent_change")) {
    columns["percent_change"] = percentChangeTableCell({
      id: "percent-change",
      width: columnWidths["percent_change"],
      isMobile
    });
  } else if (renderGhostColumns) {
    columns["percent_change"] = noRenderColumn("percent_change", columnWidths["percent_change"]);
  }

  if (columnsToShow.includes("trendline")) {
    columns["trendline"] = trendLineTableCell({ id: "trendline", isMobile });
  } else if (renderGhostColumns) {
    columns["trendline"] = noRenderColumn("trendline", columnWidths["trendline"]);
  }

  if (columnsToShow.includes("state")) {
    columns["state"] = {
      id: "state",
      display: "State",
      align: "center",
      sortable: true,
      width: columnWidths["state"] ?? COLUMN_WIDTHS.tertiary,
      render: (row) => {
        if (row.stateValue !== undefined) {
          return (
            <Typography variant="body2" maxWidth={columnContentMaxWidth(undefined, isMobile)}>
              {useRatioForValues === true ? "1 : " : ""}
              {formatValueByUnit({
                value: row.stateValue,
                unit: row.si.unit,
                precision: row.si.precision,
                isPercent: row.si.isPercent ?? false
              }) ?? "---"}
            </Typography>
          );
        }
        return <SuppressionTooltip fontSize="small" />;
      }
    };
  } else if (renderGhostColumns) {
    columns["state"] = noRenderColumn("state", columnWidths["state"]);
  }

  if (columnsToShow.includes("target")) {
    columns["target"] = {
      id: "target",
      display: (
        <Typography
          fontWeight={700}
          variant="body2"
          sx={{ textWrap: "wrap", flexWrap: "wrap", width: "70px" }}
        >
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle"
            }}
          >
            Target
            <InfoIcon sx={{ ml: "5px" }}>
              Target values come from Healthy People 2030 (health.gov/healthypeople).
            </InfoIcon>
          </span>
        </Typography>
      ),
      align: "center",
      sortable: true,
      width: columnWidths["target"] ?? COLUMN_WIDTHS.primary,
      render: (row) => {
        const anyTarget = row.si.targets !== undefined && (row.si?.targets?.length ?? 0) > 0;
        const target = row.target;
        return (
          <Box maxWidth={columnContentMaxWidth(undefined, isMobile)}>
            {anyTarget && (
              <Typography color="textSecondary" variant="body2">
                {" "}
                {target && target.value !== null
                  ? formatValueByUnit({
                      value: target.value,
                      ...getValueOptionsFromSi(row.si)
                    })
                  : ""}{" "}
              </Typography>
            )}
            {!anyTarget && (
              <SuppressionTooltipNA overrideMessage="There is no target value available for this indicator at this time." />
            )}
          </Box>
        );
      }
    };
  } else if (renderGhostColumns) {
    columns["target"] = noRenderColumn("target", columnWidths["target"]);
  }

  return columns;
};
