"use client";

import RateReviewIcon from "@mui/icons-material/RateReview";
import { Link, Stack, StackProps, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import { hotjar } from "react-hotjar";

import { useIsMobile } from "common/util/hooks/useIsMobile";

export interface HotjarSurveyLinkProps {
  /** Survey href */
  href?: string;
  /** Title for the survey link */
  title?: string;
  /**
   * Hotjar event id
   *
   * If the event id is defined, link will trigger a hotjar event and the href will be ignored
   */
  eventId?: string;
  containerSx?: StackProps["sx"];
}

export const HotjarSurveyLink = ({
  href,
  eventId,
  title = "Share feedback about this chart",
  containerSx
}: HotjarSurveyLinkProps) => {
  const isMobile = useIsMobile();
  return (
    <Stack direction={isMobile ? "row" : "row-reverse"} sx={containerSx}>
      <Link
        color="component.links"
        href={isNil(eventId) ? href : undefined}
        onClick={
          !isNil(eventId)
            ? (e) => {
                e.preventDefault();
                hotjar.event(eventId);
              }
            : undefined
        }
        component="a"
        target="blank"
        sx={{ width: "min-content", cursor: "pointer" }}
      >
        <Stack direction="row" gap={"4px"}>
          <RateReviewIcon fontSize="inherit" sx={{ my: "auto", fontSize: "1rem" }} />
          <Typography
            color="component.links"
            variant="body3"
            component="span"
            sx={{ width: "max-content" }}
          >
            {title}
          </Typography>
        </Stack>
      </Link>
    </Stack>
  );
};
