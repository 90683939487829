export interface DataStory {
  datePublished?: Date;
  url: string;
  description: string;
  title: string;
  image: string;
  imageAlt?: string;
  context: string;
  slug: string;
}

export const dataStories: DataStory[] = [
  {
    datePublished: new Date(2020, 0),
    url: "/stories/opioid-crisis",
    description:
      "In Delaware, like in many states across the nation, the statistics paint a troubling reality. Over 30,000 adults, 9,000 adolescents, and more than 82 percent of our prison population struggle with mental illness or substance use disorder. ",
    title: "Delaware's Opioid Crisis",
    image: "/images/stories/opioid-crisis/thumb.jpg",
    imageAlt: "Pills spilling from medicine bottle",
    context: "Mental Health & Substance Use Disorder",
    slug: "opioid-crisis"
  },
  {
    datePublished: new Date(2022, 3),
    url: "/stories/suicide",
    description:
      "Nearly 600 Delawareans died by suicide between 2014 and 2018. By examining the data and the characteristics of those who died, DHSS seeks to provide residents, health care professionals, local organizations, and others with information that could help prevent future suicides.",
    title: "Insights from the 2014-2018 Delaware Suicide Surveillance Study",
    image: "/images/stories/suicide/thumb.jpg",
    imageAlt: "Man in dark room",
    context: "Mental Health & Substance Use Disorder",
    slug: "suicide"
  },
  {
    datePublished: new Date(2022, 2),
    url: "/stories/vaccination",
    description:
      "Hispanic Delawareans were hit hard during the pandemic's early months and slow to be vaccinated. Now they top the vaccination tables. What happened?",
    title: "COVID-19 Vaccinations in the Hispanic Community",
    image: "/images/stories/vaccinations/thumb.jpeg",
    imageAlt: "Mother with children",
    context: "COVID-19",
    slug: "vaccinations"
  }
];
