import compact from "lodash/compact";
import isNil from "lodash/isNil";
import { getMhcLocationStats } from "graphqlApi/legacy/mhcClient";

import { DateFromApi } from "graphqlApi/customTypes";
import {
  MhcAttributionFragment,
  MhcLocationStatWithDataSeriesFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";
import {
  FetchKpiOptions,
  KpiPropsAndRelatedKpis
} from "modules/Topics/util/fetchingFunctions/kpi/types";

import { getSmallestGranularity, updateKpiTitlesWithTheSameName } from "./util";
import { logError } from "common/util/consoleHelpers";
import { getLatestDateFromArray } from "common/util/getLatestDate";
import { fetchRelatedKpi } from "modules/Topics/util/fetchingFunctions/kpi/fetchRelatedKpi";

import { KpiProps } from "common/components/KPI";
import { createKpiProps } from "./createKpiProps";

interface FetchedStatIdsReturn {
  attributions?: MhcAttributionFragment[];
  granularity?: MhcTimeSeriesGranularityEnum | null;
  kpis: KpiPropsAndRelatedKpis[];
  latestDate?: DateFromApi;
  updatedDate?: DateFromApi;
  attributionsByStat?: Record<string, MhcAttributionFragment[]>;
}

export const fetchKpiDataForStatIds = async ({
  locationId,
  statIds,
  options
}: {
  locationId: string;
  statIds: string[];
  options: FetchKpiOptions;
}): Promise<FetchedStatIdsReturn> => {
  const dates: DateFromApi[] = [];
  const updatedDates: DateFromApi[] = [];
  let attributions: MhcAttributionFragment[] = [];
  const attributionsByStat: Record<string, MhcAttributionFragment[]> = {};
  const granularities: (MhcTimeSeriesGranularityEnum | null)[] = [];
  let loadedStatForKpis: MhcLocationStatWithDataSeriesFragment[] = [];
  if (!isNil(options.granularity)) {
    loadedStatForKpis = (await getMhcLocationStats({
      locationId,
      identifiers: statIds,
      granularity: options.granularity
    })) as MhcLocationStatWithDataSeriesFragment[];
  } else if (!isNil(options.granularities)) {
    const statsByGranularity = Object.entries(options.granularities).reduce(
      (record, [id, granularity]) => {
        if (!isNil(record[granularity])) {
          return {
            ...record,
            [granularity]: [...record[granularity], id]
          };
        }
        return { ...record, [granularity]: [id] };
      },
      {} as Record<MhcTimeSeriesGranularityEnum, string[]>
    );
    loadedStatForKpis = (
      await Promise.all(
        Object.entries(statsByGranularity).flatMap(async ([granularity, identifiers]) => {
          return (await getMhcLocationStats({
            locationId,
            identifiers,
            granularity: granularity as MhcTimeSeriesGranularityEnum
          })) as MhcLocationStatWithDataSeriesFragment[];
        })
      )
    ).flat();
  } else {
    loadedStatForKpis = (await getMhcLocationStats({
      locationId,
      identifiers: statIds
    })) as MhcLocationStatWithDataSeriesFragment[];
  }
  const loadedStatDictionary: Record<string, MhcLocationStatWithDataSeriesFragment> = {};
  loadedStatForKpis.forEach((stat) => (loadedStatDictionary[stat.statIdentifier.id] = stat));
  const kpis = compact(
    await Promise.all(
      statIds.map(async (statId): Promise<KpiPropsAndRelatedKpis | null> => {
        const loadedStat = loadedStatDictionary[statId] as MhcLocationStatWithDataSeriesFragment;
        try {
          const {
            statIdentifier,
            granularity: _granularity,
            lastUpdatedOn,
            timeSeries,
            percentageChange
          } = loadedStat;
          const { attributions: statAttributions } = statIdentifier;
          dates.push(lastUpdatedOn ?? null);
          (statAttributions ?? []).forEach(({ lastUpdatedOn }) =>
            updatedDates.push(lastUpdatedOn ?? null)
          );
          const granularity = !isNil(options.granularities)
            ? options.granularities[statId]
            : _granularity;
          attributions = [...attributions, ...(statAttributions ?? [])];
          attributionsByStat[statId] = statAttributions ?? [];
          granularities.push(granularity ?? null);

          let relatedKpis: KpiProps[] = [];
          if (options.loadRelatedStats) {
            relatedKpis = await Promise.all(
              (statIdentifier.related ?? []).map(async (relatedStat) =>
                fetchRelatedKpi({ statId: relatedStat.id, locationId, options })
              )
            );
          }

          return createKpiProps({
            locationId,
            ...loadedStat,
            timeSeries: {
              ...timeSeries,
              granularity
            },
            relatedKpis,
            options,
            granularity,
            percentageChange
          });
        } catch (error) {
          logError(error, `SI: ${statId}`);
          return null;
        }
      })
    )
  );

  const maxLatestDate = getLatestDateFromArray(dates);
  const maxUpdatedDate = getLatestDateFromArray(updatedDates);
  return {
    attributions,
    attributionsByStat,
    granularity: getSmallestGranularity(granularities) ?? null,
    kpis: updateKpiTitlesWithTheSameName(kpis),
    latestDate: maxLatestDate,
    updatedDate: maxUpdatedDate
  };
};
