"use client";

import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import isNaN from "lodash/isNaN";
import { StyleFunction } from "leaflet";

import { InvestigateMapProps, MinMaxValue } from "./util/types";
import {
  MhcFeatureCollection,
  MhcGeographyEnum,
  MhcGeoJsonFeatureProperty,
  MhcLocationFragment
} from "graphqlApi/types";

import { demographicColors } from "theme/colors";
import { calculateClippedRange } from "./util/getClippedRange";
import { getColorBasedOnValue, GetColorBasedOnValueProps } from "./util/getColorBasedOnValue";
import { getInvestigateDropdownsSx } from "./util/getInvestigateDropdownsSx";
import { getInvestigateMapFeatureStyle } from "./util/getInvestigateMapFeatureStyle";
import { getSelectedGeoJson } from "./util/getSelectedGeoJson";
import { getBoundsOfInvestigationData } from "./util/helpers";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getGeographyEnumFromReadableName } from "common/util/geographyHelpers";

import { GeoMap, GeoMapProps } from "../GeoMap";
import { MapMouseEvent } from "../GeoMap/BaseMap";
import {
  activePolygonFeatureWithoutDataStyle,
  locationPickerNoValueStyle,
  noDataMapColor
} from "../GeoMap/mapStyles";
import { InvestigateRange, RangeOptions, rangesUpToAHundred } from "./styles/investigateMapStyles";
import { InvestigateMapCustomOptions } from "./InvestigateMapCustomOptions";
import { InvestigateMapDescription } from "./InvestigateMapDescription";
import { InvesitgateMapGranularities } from "./InvestigateMapGranularities";
import { InvestigateMapInvestigations } from "./InvestigateMapInvestigations";
import { InvestigateMapLegendWrapper } from "./InvestigateMapLegendWrapper";
import { investigateMapLoadStatIdentifiers } from "./investigateMapLoadStatIdentifiers";
import { InvestigateMapMarkers } from "./InvestigateMapMarkers";
export type {
  InvestigateMapAvailableGeographies,
  InvestigateMapInvestigation,
  InvestigateMapLocationReference,
  InvestigateMapMarker,
  InvestigateMapProps
} from "./util/types";

export const InvestigateMap: React.FC<InvestigateMapProps> = ({
  colorScale = "discrete",
  CustomDropdown = null,
  displayDirection = "row",
  height = "650px",
  hideGeographyGranularitySelector = false,
  ignoreFeatureValues = false,
  infoElement = null,
  sortInvestigations = true,
  width = "100%",
  hideInvestigationDropdown = false,
  ...props
}) => {
  const [investigateType, setInvestigateType] = useState<string | undefined>(undefined);
  const [loadingStatId, setLoadingStatId] = useState<string | undefined>(undefined);
  const [geoJSON, setGeoJSON] = useState<MhcFeatureCollection | undefined>();
  const [loadingMap, setLoadingMap] = useState<boolean>(false);
  const [loadingGranularity, setLoadingGranularity] = useState<boolean>(false);
  const [loadedDefaultInvestigation, setLoadedDefaultInvestigation] = useState<boolean>(false);
  const [legend, setLegend] = useState<React.ReactNode | undefined>(undefined);
  useEffect(() => {
    if (loadingStatId !== undefined || loadingMap === true) {
      return;
    }
    const filtered = props.investigations.filter(
      (investigation) => investigation.statId === props.defaultInvestigation
    );
    if (props.noInitialSelectedSi === true || loadedDefaultInvestigation === true) {
      return;
    }
    let id: string | undefined;
    if (filtered.length !== 0) {
      setInvestigateType(filtered[0]?.title ?? "");
      id = filtered[0]?.statId;
    } else {
      setInvestigateType(props.investigations[0]?.title ?? "");
      id = props.investigations[0]?.statId;
    }
    if (id) {
      const stat = props.statIdentifierReference[id];
      if (stat) {
        props.setSelectedSi(stat);
        setLoadedDefaultInvestigation(true);
      }
    }
  }, [
    props.investigations,
    props.defaultInvestigation,
    props.statIdentifierReference,
    props.setSelectedSi,
    loadingMap,
    loadingStatId,
    props,
    loadedDefaultInvestigation
  ]);

  const [selectedCustomOption, setSelectedCustomOption] = useState<string | undefined>(
    props.customOptions && props.customOptions.length > 0 ? props.customOptions[0] : undefined
  );

  const [selectedRangeForLegend, setSelectedRangeForLegend] = useState<InvestigateRange[]>(
    props.defaultRange
      ? RangeOptions[props.defaultRange](props.colorPalette)
      : rangesUpToAHundred(props.colorPalette)
  );

  const loadValuesGranularityChange = useCallback(
    async (
      geo: MhcGeographyEnum,
      id: string,
      geoLocationRef: {
        loaded: boolean;
        locationIds: string[];
      }
    ) => {
      const { data: loadedData } = await investigateMapLoadStatIdentifiers({
        statId: id,
        locationIds: [{ geography: geo, ids: geoLocationRef.locationIds }],
        granularity: "ignore"
      });
      const bounds = getBoundsOfInvestigationData(loadedData);
      if (props.setLocationIdReference) {
        const updated = {
          ...props.locationIdReference,
          [geo]: {
            ...geoLocationRef,
            loaded: true
          }
        };
        props.setLocationIdReference(updated);
      }
      const copy = [...props.investigations];
      copy.forEach((investigation) => {
        if (investigation.statId === id) {
          investigation.data = { ...investigation.data, ...loadedData };
          if (bounds !== undefined && investigation.minMax) {
            investigation.minMax = {
              ...investigation.minMax,
              [geo]: bounds
            };
          }
        }
      });
      props.setInvestigations(copy);
      setLoadingMap(false);
      setLoadingGranularity(false);
      setLoadingStatId(undefined);
    },
    [props]
  );

  useEffect(() => {
    if (props.locationIdReference) {
      const geo = getGeographyEnumFromReadableName(props.mapGranularity);
      if (geo) {
        const geoLocationRef = props.locationIdReference[geo];
        const id = props.selectedSi?.id ?? undefined;
        if (
          geoLocationRef &&
          geoLocationRef.loaded === false &&
          id &&
          loadingMap === false &&
          loadingGranularity === false &&
          loadingStatId === undefined
        ) {
          setLoadingStatId(id);
          setLoadingMap(true);
          setLoadingGranularity(true);
          void loadValuesGranularityChange(geo as MhcGeographyEnum, id, geoLocationRef);
        }
      }
    }
  }, [
    loadingMap,
    loadingGranularity,
    props.setInvestigations,
    props.investigations,
    props.locationIdReference,
    props.mapGranularity,
    props.selectedSi?.id,
    loadValuesGranularityChange,
    loadingStatId
  ]);

  const handleOnFeatureClick: MapMouseEvent = useCallback(
    (id?: string) => {
      if (!id) return;
      props.onFeatureClick && props.onFeatureClick(id);
      props.setMapSelectedFeature && props.setMapSelectedFeature(id);
      props.setSelectedId && props.setSelectedId(id);
    },
    // If we include `props` I think the map will re-render too often
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onFeatureClick, props.setMapSelectedFeature, props.setSelectedId]
  );

  useEffect(() => {
    if (props.mapGranularity) {
      const selected = getSelectedGeoJson(props.availableGeographies, props.mapGranularity);
      const selectedSi = props.selectedSi;
      if (selected && selectedSi && loadingMap === false && loadingGranularity === false) {
        const selectedInvestigation =
          props.investigations.find(({ statId }) => statId === selectedSi.id) || undefined;
        setLoadingMap(true);
        const allValues: GetColorBasedOnValueProps["allValues"] = selected?.features.map(
          ({ properties }) => {
            const { value = null } = selectedInvestigation?.data[properties.id] ?? {};
            return value;
          }
        ) as number[];
        setGeoJSON((currentValue) => {
          const updatedGeo: typeof currentValue = {
            ...selected,
            features: selected?.features.map((feature) => {
              const featureId = feature.properties.id;
              const { value, date } = selectedInvestigation?.data[featureId] ?? {};
              const formattedValue = value
                ? formatValueByUnit({
                    value,
                    unit: selectedSi.unit,
                    precision: selectedSi.precision,
                    isPercent: selectedSi.isPercent
                  })
                : undefined;
              const location = {
                ...(props.locationReference[feature.properties.id] as MhcLocationFragment)
              };
              const _dateRange = (() => {
                if (props.useCustomOptionAsDateRange === true) {
                  return selectedCustomOption;
                }
                return props.dateRange;
              })();
              const isMarker = (() => {
                if (props.markers === undefined || props.markers.length === 0) {
                  return false;
                }
                const filtered = props.markers.filter((marker) => {
                  const filtered = marker.geoJSON.features.filter(
                    (feature) => feature.properties.id === featureId
                  );
                  return filtered.length > 0;
                });
                return filtered.length > 0;
              })();
              const maxValueForInvestigation =
                selectedInvestigation?.minMax &&
                (selectedInvestigation?.minMax[
                  getGeographyEnumFromReadableName(props.mapGranularity) as MhcGeographyEnum
                ] as MinMaxValue);
              const isPercentAndMaxIs100 =
                maxValueForInvestigation &&
                selectedSi.isPercent &&
                maxValueForInvestigation?.maxValue === 100;
              const clippedRange =
                (!isPercentAndMaxIs100 && allValues?.length && calculateClippedRange(allValues)) ??
                undefined;
              const colorBasedOnValue = getColorBasedOnValue({
                featureId,
                customOptions: props.customOptions,
                hideGeographyGranularitySelector,
                investigations: props.investigations,
                selectedCustomOption,
                investigateType,
                allValues,
                colorScale,
                selectedSi,
                selectedRangeForLegend,
                selectedGranularity: props.mapGranularity,
                clippedMaxValue: clippedRange ? clippedRange[1] : undefined
              });
              const updatedFeature = {
                ...feature,
                properties: {
                  ...feature.properties,
                  value: value,
                  formattedValue: formattedValue ?? undefined,
                  color: colorBasedOnValue ?? noDataMapColor,
                  infoBoxProps: {
                    title: "",
                    results: !isNaN(value)
                      ? [
                          {
                            nameOfIndicator: selectedSi.fullName,
                            colorCode: colorBasedOnValue ?? noDataMapColor,
                            result: value,
                            formattedResult: formattedValue,
                            statIdentifier: selectedSi
                          }
                        ]
                      : undefined,
                    dateRange: date ?? _dateRange,
                    isMarker,
                    reportCallbackName: props.reportCallbackName,
                    locationName: location.name,
                    locationId: location.id,
                    selectedLocationGeography: props.mapGranularity,
                    description: selectedSi?.statCaption
                  }
                }
              };
              return updatedFeature;
            })
          };
          if (loadingGranularity === false) {
            setLoadingMap(false);
          }
          return updatedGeo;
        });
        setLegend(
          <InvestigateMapLegendWrapper
            allValues={allValues}
            selectedGranularity={props.mapGranularity}
            ignoreFeatureValues={ignoreFeatureValues}
            colorScale={colorScale}
            selectedSi={props.selectedSi}
            selectedRangeForLegend={selectedRangeForLegend}
            investigations={props.investigations}
            precision={props.selectedSi?.precision}
          />
        );
      } else {
        setGeoJSON(selected);
      }
    }
    // TODO: Reduce the number of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingMap,
    loadingGranularity,
    props.availableGeographies,
    props.investigations,
    props.mapGranularity,
    props.selectedSi
  ]);

  const featureStyle: GeoMapProps["featureStyle"] = useCallback(
    (feature: Parameters<StyleFunction<MhcGeoJsonFeatureProperty>>[0]) => {
      return getInvestigateMapFeatureStyle({
        feature: feature,
        selectedId: props.selectedId,
        ignoreFeatureValues
      });
    },
    [ignoreFeatureValues, props.selectedId]
  );

  let onFeatureMouseover: MapMouseEvent | undefined = undefined;
  let onFeatureMouseout: MapMouseEvent | undefined = undefined;
  if (ignoreFeatureValues) {
    onFeatureMouseover = (id, feature, layer) => {
      layer?.setStyle({
        ...activePolygonFeatureWithoutDataStyle,
        color: demographicColors.race.asian
      });
      layer?.bringToFront();
    };
    onFeatureMouseout = (id, feature, layer) =>
      layer?.setStyle({ ...locationPickerNoValueStyle, color: demographicColors.race.asian });
  }

  const handleCustomOptionOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCustomOption(event.target.value);
  };

  return (
    <Stack
      data-testid="InvestigateMap Container"
      direction={displayDirection === "column" ? "row" : "column"}
      sx={{ width: "100%" }}
    >
      <Stack sx={{ ...getInvestigateDropdownsSx(displayDirection) }}>
        <Grid container spacing={0} sx={{ flex: "0 0 auto" }}>
          {infoElement}
          {props.markerSectionTitle && props.markerSectionSubtitle && (
            <InvestigateMapMarkers
              markerSectionTitle={props.markerSectionTitle}
              markerSectionSubtitle={props.markerSectionSubtitle}
              markerTitle={props.markerTitle}
              markers={props.markers}
              getSelectedGeoJson={getSelectedGeoJson}
              availableGeographies={props.availableGeographies}
              setGeoJSON={setGeoJSON}
              setSelectedId={props.setSelectedId}
              selectedGranularity={props.mapGranularity}
            />
          )}
          <InvestigateMapCustomOptions
            displayDirection={displayDirection}
            markerSectionTitle={props.markerSectionTitle}
            markerSectionSubtitle={props.markerSectionSubtitle}
            customOptionTitle={props.customOptionTitle}
            customOptions={props.customOptions}
            selectedCustomOption={selectedCustomOption}
            handleCustomOptionOnChange={handleCustomOptionOnChange}
          />
          {hideGeographyGranularitySelector === false && (
            <InvesitgateMapGranularities
              displayDirection={displayDirection}
              markerSectionTitle={props.markerSectionTitle}
              markerSectionSubtitle={props.markerSectionSubtitle}
              granularityTitle={props.granularityTitle}
              defaultMapGranularity={props.defaultMapGranularity}
              availableGeographies={props.availableGeographies}
              investigations={props.investigations}
              hideInvestigationDropdown={hideInvestigationDropdown}
              selectedGranularity={props.mapGranularity}
              setSelectedGranularity={props.updateMapGranularity}
              setSelectedId={props.setSelectedId}
            />
          )}
          {props.investigations.length > 0 && !hideInvestigationDropdown && (
            <InvestigateMapInvestigations
              loadingMap={loadingMap}
              loadingStatId={loadingStatId}
              setLoadingStatId={setLoadingStatId}
              CustomDropdown={CustomDropdown}
              dataToInvestigateTitle={props.dataToInvestigateTitle}
              displayDirection={displayDirection}
              customOptions={props.customOptions}
              sortInvestigations={sortInvestigations}
              investigations={props.investigations}
              selectedCustomOption={selectedCustomOption}
              setInvestigateType={setInvestigateType}
              setSelectedRangeForLegend={setSelectedRangeForLegend}
              setLoadingMap={setLoadingMap}
              locationIdReference={props.locationIdReference}
              statIdentifierReference={props.statIdentifierReference}
              setSelectedSi={props.setSelectedSi}
              expectedGranularity={props.expectedGranularity}
              overrideStatIdLoader={props.overrideStatIdLoader}
              setInvestigations={props.setInvestigations}
              colorPalette={props.colorPalette}
              defaultInvestigation={props.defaultInvestigation}
              investigateType={investigateType}
              selectedGranularity={props.mapGranularity}
              investigationsOrder={props.investigationsOrder}
            />
          )}
          {props.dataToInvestigateDescription && (
            <InvestigateMapDescription
              displayDirection={displayDirection}
              dataToInvestigateDescription={props.dataToInvestigateDescription}
            />
          )}
        </Grid>
      </Stack>
      <Stack
        sx={{
          flex: "1 1 auto",
          display: "flex",
          gap: 2,
          flexDirection: "column",
          minWidth: "50%",
          ml: displayDirection === "column" ? 6 : 0
        }}
      >
        {geoJSON && (
          <GeoMap
            selectedFeatureId={props.selectedId}
            selectedDataIdentifier={props.selectedSi?.id}
            forceLoading={loadingMap || loadingGranularity}
            geoJSON={geoJSON}
            featureStyle={featureStyle}
            onFeatureClick={handleOnFeatureClick}
            onFeatureMouseover={onFeatureMouseover}
            onFeatureMouseout={onFeatureMouseout}
            viewReportCallback={props.viewReportCallback}
            {...props.mapProps}
            legend={legend}
            width={width}
            height={height}
          />
        )}
        {props.postMapElement}
      </Stack>
    </Stack>
  );
};
