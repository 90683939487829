import compact from "lodash/compact";

import { DateFromApi } from "graphqlApi/customTypes";
import {
  MhcAlteredLocationFragment,
  MhcAttributionFragment,
  MhcGeographyEnum,
  MhcLocation,
  MhcLocationFragment,
  MhcLocationWithoutGeoJsonFragment,
  MhcPageFragment
} from "graphqlApi/types";

import { SUPPORTED_CPR_LOCATION_GEOGRAPHIES } from "../util/constants";
import { envIsProd } from "common/util/envHelpers";
import { cprLocationPagePath } from "modules/CPR/util/cprPageRoutes";

import { MhcTopicCategories } from "layout/SideNav";
import { IndicatorTableRow } from "common/components/IndicatorTable/IndicatorTable";
import { CprOverviewData } from "modules/CPR/components/Cards/CprOverview";
import { CprChronicDiseaseData } from "./FetchFunctions/fetchChronicDisease";
import { CprClimateChangeData } from "./FetchFunctions/fetchClimateChange";
import { CprHealthyLifestylesData } from "./FetchFunctions/fetchHealthyLifestyles";
import { CprInfectiousDiseaseData } from "./FetchFunctions/fetchInfectiousDisease";
import { CprMaternalAndChildHealthData } from "./FetchFunctions/fetchMaternalAndChildHealth";
import { CprMentalHealthData } from "./FetchFunctions/fetchMentalHealth";
import { CprPopulationAndDemographicsData } from "./FetchFunctions/fetchPopulationAndDemographics";
import { CprSviData } from "./FetchFunctions/fetchSviData";

export type CprSectionData =
  | CprOverviewData
  | CprChronicDiseaseData
  | CprHealthyLifestylesData
  | CprInfectiousDiseaseData
  | CprMaternalAndChildHealthData
  | CprMentalHealthData
  | CprClimateChangeData
  | CprPopulationAndDemographicsData
  | CprSviData;

export interface CprSections {
  overview?: CprOverviewData;
  populationAndDemographics?: CprPopulationAndDemographicsData;
  svi?: CprSviData;
  chronicDisease?: CprChronicDiseaseData;
  infectiousDisease?: CprInfectiousDiseaseData;
  mentalHealth?: CprMentalHealthData;
  climateChange?: CprClimateChangeData;
  maternalAndChild?: CprMaternalAndChildHealthData;
  healthyLifestyles?: CprHealthyLifestylesData;
}

export interface CprDashboardProps extends CprSections {
  location: MhcLocationFragment | MhcAlteredLocationFragment;
  locations: MhcLocationWithoutGeoJsonFragment[];
  sideNavItems: MhcTopicCategories;
  topic: MhcPageFragment;
  attributions?: MhcAttributionFragment[];
  healthcareProviderRows?: IndicatorTableRow[];
  latestDate?: DateFromApi;
  locationsForSwitcher: MhcLocation[];
}

const baseSection = {
  supportedLocationGeographies: SUPPORTED_CPR_LOCATION_GEOGRAPHIES
};

const cprSlugs = [
  "overview",
  "population-data",
  "svi",
  "infectious-disease",
  "chronic-disease",
  "mental-health-and-substance-use",
  "climate-and-health",
  "maternal-and-child-health",
  "healthy-lifestyles"
] as const;

export type CprSlug = (typeof cprSlugs)[number];

export interface CprSectionBaseProps {
  slug: CprSlug;
  title: string;
  id: keyof CprSections;
}

export interface CprSectionElementProps extends CprSectionBaseProps {
  location: MhcLocationFragment;
  data?: CprSectionData;
  tableRows?: IndicatorTableRow[];
  latestDate?: DateFromApi;
}

export interface CprSection extends CprSectionBaseProps {
  supportedLocationGeographies: MhcGeographyEnum[];
}

export const CPR_SECTIONS: CprSection[] = compact([
  { ...baseSection, slug: "overview", title: "Overview", id: "overview" },
  {
    ...baseSection,
    slug: "population-data",
    title: "Population Data",
    id: "populationAndDemographics"
  },
  {
    ...baseSection,
    slug: "svi",
    title: "Social Vulnerability Index",
    id: "svi",
    supportedLocationGeographies: [MhcGeographyEnum.CensusTract]
  },
  {
    ...baseSection,
    slug: "infectious-disease",
    title: "Infectious Disease",
    id: "infectiousDisease"
  },
  {
    ...baseSection,
    slug: "chronic-disease",
    title: "Chronic Disease",
    id: "chronicDisease"
  },
  {
    ...baseSection,
    slug: "mental-health-and-substance-use",
    title: "Mental Health and Substance Use",
    id: "mentalHealth"
  },
  !envIsProd()
    ? {
        ...baseSection,
        slug: "climate-and-health",
        title: "Climate and Health",
        id: "climateChange"
      }
    : null,
  {
    ...baseSection,
    slug: "maternal-and-child-health",
    title: "Maternal and Child Health",
    id: "maternalAndChild"
  },
  {
    ...baseSection,
    slug: "healthy-lifestyles",
    title: "Healthy Lifestyles",
    id: "healthyLifestyles"
  }
]);

export const CprSectionIds = CPR_SECTIONS.map(({ id }) => id);

export const cprSectionTopics = (locationId: string): MhcPageFragment[] =>
  CPR_SECTIONS.slice(1, CPR_SECTIONS.length).map(({ title, slug, id }) => ({
    id,
    name: title,
    href: cprLocationPagePath({ anchorId: slug, locationId }),
    pageId: id,
    category: "portals",
    slug: id,
    topicType: "section"
  }));
