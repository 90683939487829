import { ReactElement } from "react";
import { Box, Grid, Stack } from "@mui/material";
import defaultsDeep from "lodash/defaultsDeep";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";

import { STAT_IDENTIFIER_TYPES } from "graphqlApi/client/mhcClient/statIdentifier/constants";

import { KpiPropsAndRelatedKpis } from "../util/fetchingFunctions/kpi/types";
import { MhcAttributionFragment } from "graphqlApi/types";

import { kpiTitleOverrides } from "../content/kpis/dictionary";
import { IconId, IconIdEnum } from "common/components/Icons/utils";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { KPI, KpiProps } from "common/components/KPI";
import { KPIIconKey } from "common/components/KpiList/KpiIconKey";
import { AttributionPopover } from "modules/ECDC/components/AttributionPopover";

export type TopicKpisProps = {
  kpis: KpiPropsAndRelatedKpis[] | null;
  attributionsByStat?: Record<string, MhcAttributionFragment[]>;
  displayGroupTitles?: boolean;
};

export const getFeaturedKpiProps = (kpiProps: KpiProps) => {
  return defaultsDeep(
    {
      enhancement: {
        showTarget: true,
        trendProps: {
          ...(kpiProps.enhancement?.trendProps ?? {}),
          showAllTicks: true
        }
      },
      sx: { mb: 0 }
    },
    kpiProps
  );
};

const TopicKpis: React.FC<TopicKpisProps> = ({
  kpis = null,
  attributionsByStat,
  displayGroupTitles
}) => {
  const getKpiTitle = (id: string, title: string | ReactElement): ReactElement | string => {
    const override = kpiTitleOverrides[id] ?? title;
    return override;
  };

  const sortKpisByType = (array: KpiPropsAndRelatedKpis[]) =>
    sortBy(array, ({ id }) => {
      const match = id.match(new RegExp(STAT_IDENTIFIER_TYPES.join("|")));
      return match ? STAT_IDENTIFIER_TYPES.indexOf(match[0]) : -1;
    });

  const showIconLegend = (() => {
    if (isNil(kpis)) return false;
    return (
      kpis?.filter(
        (props) => !isNil(props.enhancement?.targetProps) && props.enhancement?.showTarget === true
      ).length > 1
    );
  })();

  const kpiGroups = (() => {
    const groups = groupBy(kpis, ({ id }) =>
      id.replace(new RegExp(STAT_IDENTIFIER_TYPES.join("|")), "")
    );
    Object.entries(groups).forEach(([id, kpis]) => (groups[id] = sortKpisByType(kpis)));
    return groups;
  })();

  if (!kpis?.length) return null;

  const iconsToShow = [IconIdEnum.Improving] as IconId[];
  iconsToShow.push(IconIdEnum.NeedsAttention);

  const renderSingleKpi = (kpiProps: KpiPropsAndRelatedKpis, displayTitle = false) => (
    <Box>
      {kpiProps.kpiGroupTitle && displayTitle && (
        <ContentCardSectionTitle title={kpiProps.kpiGroupTitle} />
      )}
      <Grid item xs={12} display={"flex"}>
        <KPI
          {...getFeaturedKpiProps(kpiProps)}
          valueSize="x-large"
          fillContainer
          title={getKpiTitle(kpiProps.id, kpiProps.title ?? "")}
        />
      </Grid>
    </Box>
  );

  const renderKpiGroup = (group: KpiPropsAndRelatedKpis[]) => {
    const [primaryKpi, ..._relatedKpis] = group;
    // Remove trend props from related KPIs so a trend is only rendered in the primary KPI
    const relatedKpis = _relatedKpis.map((kpi) => ({
      ...kpi,
      enhancement: {
        ...kpi.enhancement,
        trendProps: null
      }
    }));
    return (
      <Box>
        {displayGroupTitles && primaryKpi?.kpiGroupTitle && (
          <ContentCardSectionTitle title={primaryKpi?.kpiGroupTitle ?? ""} />
        )}
        <Grid container spacing={2}>
          {primaryKpi && (
            <Grid item xs={12} display={"flex"}>
              <KPI {...getFeaturedKpiProps(primaryKpi)} valueSize="x-large" fillContainer />
            </Grid>
          )}
          <Grid container item xs={12} display={"flex"} spacing={2}>
            {relatedKpis?.map((relatedKpiProps: KpiProps, i: number) => (
              <Grid key={i} item xs={12} md={6} display={"flex"}>
                <KPI
                  {...relatedKpiProps}
                  valueSize="large"
                  sx={{ ...relatedKpiProps.sx, mb: 0 }}
                  fillContainer
                  title={getKpiTitle(relatedKpiProps.id, relatedKpiProps.title ?? "")}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {kpis?.length && (
        <Box display="flex" flexDirection="column" gap={4}>
          {showIconLegend && (
            <KPIIconKey title="Icon" iconsToShow={iconsToShow} alignRight={false} />
          )}
          <Stack gap={5}>
            {Object.values(kpiGroups)?.map((group, i) => {
              const attributions = attributionsByStat
                ? group.flatMap(({ id }) => attributionsByStat[id] ?? []) ?? []
                : null;
              return (
                <Stack key={i} gap={0}>
                  {group.length > 1 && renderKpiGroup(group)}
                  {group.length === 1 && renderSingleKpi(group[0] as KpiPropsAndRelatedKpis, false)}
                  {attributions && (
                    <AttributionPopover sx={{ mt: 2 }} attributions={attributions ?? []} />
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default TopicKpis;
