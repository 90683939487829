import Image from "next/legacy/image";
import { Grid, Link, Typography } from "@mui/material";
import helpIsHereLogo from "public/images/topics/mental-health-substance-use/helpIsHere.svg";

import { ContentCard } from "layout/card/ContentCard";

interface Props {
  renderLongMessage?: boolean;
}

export const TopicTreatmentProvidersContent: React.FC<Props> = ({ renderLongMessage = true }) => (
  <Grid container spacing={4}>
    <Grid item xs={12} sm={12} md={6} display="flex" alignItems="center">
      {renderLongMessage && (
        <Typography>
          The treatment providers and naloxone distribution centers map is currently offline while
          we work to bring the provider list back up to date. In the meantime, please see the{" "}
          <a
            href="https://www.helpisherede.com/#recovery-stories"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help is Here – Treatment & Recovery
          </a>{" "}
          website to learn where treatment centers are located.
        </Typography>
      )}
      {!renderLongMessage && (
        <Typography>
          Please see the{" "}
          <Link href="//www.helpisherede.com/">Help is Here – Treatment & Recovery</Link> website to
          learn where treatment centers are located.
        </Typography>
      )}
    </Grid>
    <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="center">
      <a
        href="https://www.helpisherede.com/#recovery-stories"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={helpIsHereLogo} alt="Help is Here Delaware" objectFit="contain" />
      </a>
    </Grid>
  </Grid>
);

export const TopicTreatmentProviders: React.FC<Props> = ({ renderLongMessage = true }) => {
  return (
    <ContentCard title="Treatment Providers and Naloxone Distribution Centers">
      <TopicTreatmentProvidersContent renderLongMessage={renderLongMessage} />
    </ContentCard>
  );
};
